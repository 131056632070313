import React, { useEffect, useState, useRef, useCallback } from 'react';
import '../styles/salesReport.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { getLabel, eng_labels, getUnit } from '../services/labels';
import { getBrandColor, getImage, getItemdata, makeSalesSheetTitles } from '../services/dataservice';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
// const exportPdf = () => {
//     html2canvas(document.querySelector("#report"), { scale: 2 }).then(canvas => {
//         const imgData = canvas.toDataURL('image/png');
//         const pdf = new jsPDF('p', 'pt', 'a4', true, 16);
//         const width = pdf.internal.pageSize.getWidth();
//         const height = (canvas.height * width) / canvas.width;
//         // pdf.addImage(imgData, 'PNG', 0, 0, 600, 0, undefined, false);
//         pdf.addImage(imgData, 'PNG', 0, 0, width, height, '', 'FAST');
//         pdf.save("download.pdf");
//     });
// };


const makeLogoURL = (brandName) => {
    try{
        return require(`../static/logos/${brandName.trim().toUpperCase().replace(/\s+/g, '_')}.png`);
    }
    catch(e)
    {
        return require('../static/logos/FGF.png');
    }
}





const SalesReport = ({ productID, locale, subLocale, metric, pdfReady }) => {

    const [loading, setLoading] = useState(true)
    const [itemdata, setItemdata] = useState(null)

    const [imgCount, setImgCount] = useState(0);
    const [fontSize1, setFontSize1] = useState(12);
    const [fontSize2, setFontSize2] = useState(12);

    const [brandcolor, setBrandColor] = useState();
    const myDivRef = useRef(null);
    const [frontImg, setFrontImg] = useState('');
    const [backImg, setBackImg] = useState('');
    const [nurtiImg, setNutriImg] = useState('');

    const [isImageLoading, setIsImageLoading] = useState(true);


    // const getImageofReport = () => {
    //     html2canvas(document.querySelector("#report"), { scale: 2 }).then(canvas => {
    //         const imgData = canvas.toDataURL('image/png');
    //         const imgDiv = document.getElementById("ReportImageDiv");
    //         imgDiv.style.display = "flex";
    //         imgDiv.style.justifyContent = "center";
    //         // imgDiv.innerHTML = `<img src='' id='reportImg' alt='Report not found' className='imgReport' />`;
    //         // document.getElementById("reportImg").src = imgData
    //         // setLoading(false)
    //     });
    // }

    // const measuredRef = useCallback((node) => {
    //     if (node !== null) {
    //         //   alert(node.getBoundingClientRect().height);
    //         //   alert("clintejf"+ node.scrollHeight)
    //         if (node.getBoundingClientRect().height < node.scrollHeight) {
    //             const collection = document.getElementsByClassName("sheetDesc");
    //             console.log(node.getBoundingClientRect().height, node.scrollHeight)
    //             const heightDifference = node.scrollHeight - node.getBoundingClientRect().height;
    //             const current = fontSize;
    //             console.log(heightDifference);
    //             const newSize = current - heightDifference*0.01;
    //             console.log(newSize)
    //             setFontSize(newSize)
    //             console.log(collection)
    //             // console.log(fontSize);
    //             // collection
    //         }
    //     }
    // }, []);


    const divRef = useRef(null);
   
    
    const measuredRef = useCallback((node) => {
        if (
            node !== null &&
            itemdata &&
            ((itemdata.handling_Instructions && itemdata.handling_Instructions.length > 200) ||
            (itemdata.handling_Instructions_fr && itemdata.handling_Instructions_fr.length > 200)) &&
            ((itemdata.features_and_benefits && itemdata.features_and_benefits.length > 200) ||
            (itemdata.features_and_benefits_fr && itemdata.features_and_benefits_fr.length > 200))
        ) {
            divRef.current = node;
            const heightDifference = node.scrollHeight - node.getBoundingClientRect().height;
            
            setFontSize1(prevSize => {
                let newSize = prevSize;
                if (heightDifference > 0 && prevSize > 9) {
                    newSize = Math.max(7, prevSize - heightDifference * 0.04);
                } else if (heightDifference <= 0 && prevSize < 13) {
                    newSize = Math.min(13, prevSize + Math.abs(heightDifference) * 0.1);
                }
                return newSize !== prevSize ? newSize : prevSize;
            });
        }
    }, [itemdata]); // Remove fontSize1 from dependencies
    
    useEffect(() => {
        if (divRef.current) {
            measuredRef(divRef.current);
        }
    }, [measuredRef]);
    
    const divRefDes = useRef(null);
    const measuredRefDes = useCallback((node) => {
        if (
            node !== null &&
            itemdata &&
            ((itemdata.item_description && itemdata.item_description.length > 200) ||
            (itemdata.item_description_fr && itemdata.item_description_fr.length > 200))
        ) {
            divRefDes.current = node;
            const heightDifference = node.scrollHeight - node.getBoundingClientRect().height;
            
            setFontSize2(prevSize => {
                let newSize = prevSize;
                if (heightDifference > 0 && prevSize > 5 ) {
                    newSize = Math.max(7, prevSize - heightDifference * 0.04);
                }else if (heightDifference == 0 && prevSize == 13  && (itemdata.item_description.length > 200 || itemdata.item_description_fr.length > 200) && locale!=subLocale && subLocale != 'none'){
                    newSize = Math.max(7, prevSize - 6);
                } 
                else if (heightDifference <= 0 && prevSize < 13) {
                    newSize = Math.min(13, prevSize + Math.abs(heightDifference) * 0.1);
                }
                //if (heightDifference >= 0 && prevSize > 5 && (itemdata.item_description.length > 200 || itemdata.item_description_fr.length > 200) ) {
                //     newSize = Math.max(5, prevSize - heightDifference * 4);
                // } else if (heightDifference < 0 && prevSize < 11) {
                //     newSize = Math.min(13, prevSize + Math.abs(heightDifference) * 0.1);
                // }
                return newSize !== prevSize ? newSize : prevSize;
            });
        }
    }, [itemdata]);
    
    useEffect(() => {
        if (divRefDes.current) {
            measuredRefDes(divRefDes.current);
        }
    }, [measuredRefDes]);
    
    const handleImageError = (event) => {
        const img = event.target;
        img.onError = null; // Note the lowercase 'e' in 'onerror'
        img.onLoad = null
        setIsImageLoading(false);
        img.src = require('../static/images/blank.png');
      }


    //   const pageStyle = {
    //     backgroundColor: "red",
    //     pageBreakBefore: "always" 
    //   };

    useEffect(() => {
        let ignore = false
        setLoading(true)
        setFontSize1(13);
        setFontSize2(13);
        getItemdata(productID, metric, locale, subLocale).then((res) => {
            if (!ignore) {
                setItemdata(res)
                setLoading(false)
                if (res.brand) {
                    setBrandColor(getBrandColor(res.brand))
                    // setBrandLogo(makeLogoURL(res.brand))
                }
                pdfReady(true)

            }
        }).catch((e) => {
            setLoading(true)
            alert("Somthing went wrong! Refresh the page and retry.")
        });

        getImage(productID, "a", "small").then((d) => {
            setFrontImg(d)
        })

        getImage(productID, "b", "small").then((d) => {
            setBackImg(d)
        })

        getImage(productID, "itemnutrition", "clear").then((d) => {
            setNutriImg(d)
        })

        return () => {
            ignore = true
        }
    }, [productID, locale, subLocale, metric]);

    const description = locale === 'en' ? itemdata?.ingredients_en : itemdata?.ingredients_fr;
    const subDescription = locale === subLocale ? '' : (subLocale !== 'none' ? (subLocale === 'en' ? itemdata?.ingredients_en : itemdata?.ingredients_fr) : '');
    const handlingInstructions = locale === 'en' ? itemdata?.handling_Instructions : itemdata?.handling_Instructions_fr;
    const subHandlingInstructions = locale === subLocale ? '' : (subLocale !== 'none' ? (subLocale === 'en' ? itemdata?.handling_Instructions : itemdata?.handling_Instructions_fr) : '');
    const featuresAndBenefits = locale === 'en' ? itemdata?.features_and_benefits : itemdata?.features_and_benefits_fr;
    const subFeaturesAndBenefits = locale === subLocale ? '' : (subLocale !== 'none' ? (subLocale === 'en' ? itemdata?.features_and_benefits : itemdata?.features_and_benefits_fr) : '');
    const pageStyle = {
       
        pageBreakAfter: "always" 
      };
    return (
    ((itemdata?.ingredients_en?.length > 890 || itemdata?.ingredients_fr?.length > 890) &&
        (itemdata?.ingredients_en !== null || itemdata?.ingredients_fr !== null))
         ?(
        <div className='mainDiv'>
            {/* {loading ? (<span className='loader'></span>) : (<button className='btn btn-warning btnDownload' onClick={exportPdf}>
                Download PDF <FontAwesomeIcon icon={faDownload} />
            </button>)} */}

            {!loading ? (<div id="ReportImageDiv">
                {/* <p>report loading...</p> */}
                <div id="reportPage1"  className="containerReport" style={pageStyle}>
                    <div className="inside row1">
                        <div className="insiderflex1">
                            <img src={makeLogoURL(itemdata.brand)} alt="Image not found" className="brandImg" onLoad={() => { setImgCount(imgCount + 1) }} />
                        </div>
                        <div className="insiderflex2">
                            {/* <div ref={measuredRefDes} style={{ fontSize: `${fontSize2}px`}}> */}
                            <div ref={measuredRefDes} style={{ fontSize: '16.5px'}}>
                                {/* <p className="sheetTitle" style={{ color: brandcolor }}>{locale=='en'? itemdata.case_Label_Description : itemdata.case_Label_Description_Alternate ?? 'Loading...'}</p> */}
                                <p className="sheetTitle" style={{ color: brandcolor }}>
                                        {locale === subLocale || subLocale === "none" ? (locale === 'en' ? itemdata.case_Label_Description : itemdata.case_Label_Description_fr ?? 'Loading...')
                                        : ((locale === 'en' ? itemdata.case_Label_Description: itemdata.case_Label_Description_fr ?? 'Loading...') 
                                        + ((itemdata.case_Label_Description && itemdata.case_Label_Description_fr) ? "/" : "")
                                        + (locale !== 'en' ? itemdata.case_Label_Description : itemdata.case_Label_Description_fr ?? 'Loading...'))}
                                </p>

                                <p className="sheetDesc" > {locale=='en'? itemdata.item_description: itemdata.item_description_fr}
                                </p>
                                <p className="sheetDesc" > {locale==subLocale ? '': (subLocale!='none'? (subLocale=='en'? itemdata.item_description: itemdata.item_description_fr): '')}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="inside row2">
                        <div className="insiderflex1">
                            <div className="tablediv">
                                <p className="sheetTitle" style={{ color: brandcolor }}>{makeSalesSheetTitles("Product Specifications",locale, subLocale)}</p>
                                <table>
                                    <tbody>
                                        {Object.entries(eng_labels).map(([key, value]) => (
                                            <tr key={key} className='tableRow'>
                                                <th className='data'>{getLabel(locale, subLocale, key)}</th>
                                                <td className='data' >
                                                {
                                                    itemdata[key] === itemdata.packaging_description
                                                    ? (locale === subLocale || subLocale === "none"
                                                    ? (locale === 'en' ? itemdata.packaging_description : itemdata.packaging_description_fr)
                                                    : (locale === 'en' ? itemdata.packaging_description : itemdata.packaging_description_fr)
                                                    + ((itemdata.packaging_description && itemdata.packaging_description_fr) ? "/" : "")
                                                    + (locale !== 'en' ? itemdata.packaging_description : itemdata.packaging_description_fr))
                                                    : itemdata[key] === itemdata.certification_Name
                                                    ? (locale === subLocale || subLocale === "none"
                                                    ? (locale === 'en' ? itemdata.certification_Name : itemdata.certification_Name_fr)
                                                    : (locale === 'en' ? itemdata.certification_Name : itemdata.certification_Name_fr)
                                                    + ((itemdata.certification_Name && itemdata.certification_Name_fr) ? "/" : "")
                                                    + (locale !== 'en' ? itemdata.certification_Name : itemdata.certification_Name_fr))
                                                    : (itemdata[key] ?? '')
                                                }

                                                    {/* { getUnit(metric, key)}  */}
                                                    {getUnit(metric, key) === " days"? ( locale === subLocale || subLocale === "none"? (locale === 'en' ? " days" : " jours"): (locale === 'en' ? " days" : " jours") + "/" + (locale !== 'en' ? " days" : " jours")): getUnit(metric, key)}
                                                    </td>
                                            </tr>
                                        ))}
                                        {/* {Array.from({ length: iterations }, (_, index) => (
                                    <tr key={index} className='tableRow'>
                                        <th className='data'>{getLabel(locale, subLocale, "Item_No")}</th>
                                        <td className='data'>81999 </td>
                                    </tr>
                                ))} */}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="insiderIMGflex1">
                            {isImageLoading && <div>Images Loading...</div>}
                            <div className="imagedataDiv">
                                <img src={frontImg} alt="Image not found" className="itemImg" onLoad={() => { setIsImageLoading(false) }} onError={handleImageError} />
                                <img src={backImg} alt="Image not found" className="itemImg" onError={handleImageError} />
                            </div>
                            {/* <div  className="featuresDiv" ref={measuredRef} style={{ fontSize: `${fontSize1}px`}}> */}
                            <div  className="featuresDiv" ref={measuredRef} style={{ fontSize: `16.5px`}}>
                            {(featuresAndBenefits || subFeaturesAndBenefits) && ( 
                                <p className="sheetTitle" style={{ color: brandcolor }}>{makeSalesSheetTitles("Features & Benefits",locale, subLocale)}</p>
                            )} 
                            <div>
                           {featuresAndBenefits && (
                                <div className="sheetDescdiv2">
                                   <ul className='featuresList'  >
                                        {locale=='en'?
                                            itemdata.features_and_benefits.split('.').map((data, index) => (
                                                data.trim() ? <li key={index}>{data}</li> : null
                                            )):
                                            itemdata.features_and_benefits_fr.split('.').map((data, index) => (
                                                data.trim() ? <li key={index}>{data}</li> : null
                                            ))
                                        }
                                    </ul>
                                </div>
                                )}
                                {subFeaturesAndBenefits && (
                                <div className="sheetDescdiv2" >
                                    <ul className='featuresList'>
                                    {locale==subLocale ? '': (subLocale!='none'? (subLocale=='en'? 
                                            itemdata.features_and_benefits.split('.').map((data, index) => (
                                                data.trim() ? <li key={index}>{data}</li> : null
                                            )): itemdata.features_and_benefits_fr.split('.').map((data, index) => (
                                                data.trim() ? <li key={index}>{data}</li> : null
                                            ))): '')}
                                    </ul>
                                </div>
                                 )}
                                 </div>
                            </div>
                            {/* <div  className="featuresDiv" ref={measuredRef} style={{ fontSize: `${fontSize1}px`,marginTop:'10%'}}  > */}
                            <div  className="featuresDiv" ref={measuredRef} style={{ fontSize: `16.5px`,marginTop:'10%'}}  >
                             {(handlingInstructions || subHandlingInstructions) && (
                             <p className="sheetTitle" style={{ color: brandcolor }}>{makeSalesSheetTitles("Handling Instructions", locale, subLocale)}</p>
                             )}
        
                            {handlingInstructions && (
                                <p className="sheetDescdiv2"   >
                                {locale === 'en' ? itemdata.handling_Instructions : itemdata.handling_Instructions_fr}
                                </p>
                            )}
                            {subHandlingInstructions && (
                                <p className="sheetDescdiv2" >
                                {locale === subLocale ? '' : (subLocale !== 'none' ? (subLocale === 'en' ? itemdata.handling_Instructions : itemdata.handling_Instructions_fr) : '')}
                                </p>
                            )}                        
                            </div>              
                        </div>
                    </div>

{/* 
{(itemdata.ingredients_en.length > 1400 || itemdata.ingredients_fr.length > 1500) ? (
    // Render this block if ingredients length is greater than 1500
    <div className="inside row3">
        <div className="insiderIMGflex2">
            <div>
            </div>
        </div>
        <div className="insiderIMGflex1">
            <div className="imagedataDiv1">
                <img src={nurtiImg} alt="Image not found" className="nutriImg" onError={handleImageError} />
            </div>
        </div>
        <div className="page-break">
          
        <div className="inside row3" >
        <div className="insiderIMGflex2 red-background">
            <div>
                <p className="sheetTitle" style={{ color: brandcolor }}>
                    {makeSalesSheetTitles("Ingredients", locale, subLocale)}
                </p>
                <p className="sheetDescIng">
                    {locale === 'en' ? itemdata.ingredients_en : itemdata.ingredients_fr}
                </p>
                <p className="sheetDescIng">
                    {locale === subLocale ? '' : (subLocale !== 'none' ? (subLocale === 'en' ? itemdata.ingredients_en : itemdata.ingredients_fr) : '')}
                </p>
            </div>
        </div>
        <div className="insiderIMGflex1">
            <div className="imagedataDiv1">
          
            </div>
        </div>
    </div>
   
    </div>
    </div>
) : (
    // Render this block if ingredients length is less than or equal to 1500
    <div className="inside row3">
        <div className="insiderIMGflex2">
            <div>
                <p className="sheetTitle" style={{ color: brandcolor }}>
                    {makeSalesSheetTitles("Ingredients", locale, subLocale)}
                </p>
                <p className="sheetDescIng">
                    {locale === 'en' ? itemdata.ingredients_en : itemdata.ingredients_fr}
                </p>
                <p className="sheetDescIng">
                    {locale === subLocale ? '' : (subLocale !== 'none' ? (subLocale === 'en' ? itemdata.ingredients_en : itemdata.ingredients_fr) : '')}
                </p>
            </div>
        </div>
        <div className="insiderIMGflex1">
            <div className="imagedataDiv1">
                <img src={nurtiImg} alt="Image not found" className="nutriImg" onError={handleImageError} />
            </div>
        </div>
       
    </div>
)} 
*/}
    <div className="inside row3">
        <div className="insiderIMGflex2">
            <div>
            </div>
        </div>
        <div className="insiderIMGflex1">
            <div className="imagedataDiv1">
                <img src={nurtiImg} alt="Image not found" className="nutriImg" onError={handleImageError} />
            </div>
        </div>
        <div className="page-break">
          
        {/* <div>
            <p className="sheetTitle" style={{ color: brandcolor }}>
                {makeSalesSheetTitles("Ingredients", locale, subLocale)}
            </p>
            <p className="sheetDescIng">
                {locale === 'en' ? itemdata.ingredients_en : itemdata.ingredients_fr}
            </p>
            <p className="sheetDescIng">
                {locale === subLocale ? '' : (subLocale !== 'none' ? (subLocale === 'en' ? itemdata.ingredients_en : itemdata.ingredients_fr) : '')}
            </p>
        </div> */}
         
    </div>
    </div>

                   {/* <div className="inside row3">
                        <div className="insiderIMGflex2">
                        <div>
                                <p className="sheetTitle" style={{ color: brandcolor }}>{makeSalesSheetTitles("Ingredients",locale, subLocale)}</p>
                                <p className="sheetDescIng"> {locale=='en'? itemdata.ingredients_en: itemdata.ingredients_fr}
                                </p>
                                <p className="sheetDescIng"> {locale==subLocale ? '': (subLocale!='none'? (subLocale=='en'? itemdata.ingredients_en: itemdata.ingredients_fr): '')}
                                </p>                       
                             </div>
                            
                        </div>
                        <div className="insiderIMGflex1">
                            <div className="imagedataDiv1">
                                <img src={nurtiImg} alt="Image not found" className="nutriImg" onError={handleImageError} />
                            </div>
                        </div>
                    </div>
                    <div className='bottomCorner'>
                                <p className="sheetfooter">Last Modified: {itemdata.modified_Date ?? ''}</p>
                                <p className="sheetfooter" style={{ color: brandcolor }}>{itemdata.brand_Website ?? ''}</p>
                                <p className="sheetfooter">{''}</p>
                            </div> */}
                </div>
                <div id='reportPage2' className='containerReport' style={pageStyle}>
                <div className="inside row3" >
        <div className="insiderIMGflex2">
            <div>
                <p className="sheetTitle" style={{ color: brandcolor }}>
                    {makeSalesSheetTitles("Ingredients", locale, subLocale)}
                </p>
                <p className="sheetDescIng">
                    {locale === 'en' ? itemdata.ingredients_en : itemdata.ingredients_fr}
                </p>
                <p className="sheetDescIng">
                    {locale === subLocale ? '' : (subLocale !== 'none' ? (subLocale === 'en' ? itemdata.ingredients_en : itemdata.ingredients_fr) : '')}
                </p>
            </div>
        </div>
        <div className="insiderIMGflex1">
            <div className="imagedataDiv1">
                {/* <img src={nurtiImg} alt="Image not found" className="nutriImg" onError={handleImageError} /> */}
            </div>
        </div>
    </div>
    <div className='footer'> 
        <p className="sheetfooter">Last Modified: {itemdata.modified_Date ?? ''}</p>
        <p className="sheetfooter" style={{ color: brandcolor }}>{itemdata.brand_Website ?? ''}</p>
        <p className="sheetfooter">{''}</p>
        </div>
    </div>
    
            </div>) : <span className='loader'></span>}





        </div>) :(
            <div className='mainDiv'  >
          
            {!loading ? (<div id="ReportImageDiv">
               <div id="report"  className="containerReport" style={pageStyle}>
                    <div className="inside row1">
                        <div className="insiderflex1">
                            <img src={makeLogoURL(itemdata.brand)} alt="Image not found" className="brandImg" onLoad={() => { setImgCount(imgCount + 1) }} />
                        </div>
                        <div className="insiderflex2">
                            {/* <div ref={measuredRefDes} style={{ fontSize: `${fontSize2}px`}}> */}
                            <div ref={measuredRefDes} style={{ fontSize: `16.5px`}}>
                                {/* <p className="sheetTitle" style={{ color: brandcolor }}>{locale=='en'? itemdata.case_Label_Description : itemdata.case_Label_Description_Alternate ?? 'Loading...'}</p> */}
                                <p className="sheetTitle" style={{ color: brandcolor }}>
                                        {locale === subLocale || subLocale === "none" ? (locale === 'en' ? itemdata.case_Label_Description : itemdata.case_Label_Description_fr ?? 'Loading...')
                                        : ((locale === 'en' ? itemdata.case_Label_Description: itemdata.case_Label_Description_fr ?? 'Loading...') 
                                        + ((itemdata.case_Label_Description && itemdata.case_Label_Description_fr) ? "/" : "")
                                        + (locale !== 'en' ? itemdata.case_Label_Description : itemdata.case_Label_Description_fr ?? 'Loading...'))}
                                </p>

                                <p className="sheetDesc" > {locale=='en'? itemdata.item_description: itemdata.item_description_fr}
                                </p>
                                <p className="sheetDesc" > {locale==subLocale ? '': (subLocale!='none'? (subLocale=='en'? itemdata.item_description: itemdata.item_description_fr): '')}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="inside row2">
                        <div className="insiderflex1">
                            <div className="tablediv">
                                <p className="sheetTitle" style={{ color: brandcolor }}>{makeSalesSheetTitles("Product Specifications",locale, subLocale)}</p>
                                <table>
                                    <tbody>
                                        {Object.entries(eng_labels).map(([key, value]) => (
                                            <tr key={key} className='tableRow'>
                                                <th className='data'>{getLabel(locale, subLocale, key)}</th>
                                                <td className='data' >
                                                {
                                                    itemdata[key] === itemdata.packaging_description
                                                    ? (locale === subLocale || subLocale === "none"
                                                    ? (locale === 'en' ? itemdata.packaging_description : itemdata.packaging_description_fr)
                                                    : (locale === 'en' ? itemdata.packaging_description : itemdata.packaging_description_fr)
                                                    + ((itemdata.packaging_description && itemdata.packaging_description_fr) ? "/" : "")
                                                    + (locale !== 'en' ? itemdata.packaging_description : itemdata.packaging_description_fr))
                                                    : itemdata[key] === itemdata.certification_Name
                                                    ? (locale === subLocale || subLocale === "none"
                                                    ? (locale === 'en' ? itemdata.certification_Name : itemdata.certification_Name_fr)
                                                    : (locale === 'en' ? itemdata.certification_Name : itemdata.certification_Name_fr)
                                                    + ((itemdata.certification_Name && itemdata.certification_Name_fr) ? "/" : "")
                                                    + (locale !== 'en' ? itemdata.certification_Name : itemdata.certification_Name_fr))
                                                    : (itemdata[key] ?? '')
                                                }

                                                    {/* { getUnit(metric, key)}  */}
                                                    {getUnit(metric, key) === " days"? ( locale === subLocale || subLocale === "none"? (locale === 'en' ? " days" : " jours"): (locale === 'en' ? " days" : " jours") + "/" + (locale !== 'en' ? " days" : " jours")): getUnit(metric, key)}
                                                    </td>
                                            </tr>
                                        ))}
                                        {/* {Array.from({ length: iterations }, (_, index) => (
                                    <tr key={index} className='tableRow'>
                                        <th className='data'>{getLabel(locale, subLocale, "Item_No")}</th>
                                        <td className='data'>81999 </td>
                                    </tr>
                                ))} */}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="insiderIMGflex1">
                            {isImageLoading && <div>Images Loading...</div>}
                            <div className="imagedataDiv">
                                <img src={frontImg} alt="Image not found" className="itemImg" onLoad={() => { setIsImageLoading(false) }} onError={handleImageError} />
                                <img src={backImg} alt="Image not found" className="itemImg" onError={handleImageError} />
                            </div>
                            {/* <div  className="featuresDiv" ref={measuredRef} style={{ fontSize: `${fontSize1}px`}}> */}
                            <div  className="featuresDiv" ref={measuredRef} style={{ fontSize: `16.5px`}}>
                            {(featuresAndBenefits || subFeaturesAndBenefits) && ( 
                                <p className="sheetTitle" style={{ color: brandcolor }}>{makeSalesSheetTitles("Features & Benefits",locale, subLocale)}</p>
                            )} 
                            <div>
                           {featuresAndBenefits && (
                                <div className="sheetDescdiv2">
                                   <ul className='featuresList'  >
                                        {locale=='en'?
                                            itemdata.features_and_benefits.split('.').map((data, index) => (
                                                data.trim() ? <li key={index}>{data}</li> : null
                                            )):
                                            itemdata.features_and_benefits_fr.split('.').map((data, index) => (
                                                data.trim() ? <li key={index}>{data}</li> : null
                                            ))
                                        }
                                    </ul>
                                </div>
                                )}
                                {subFeaturesAndBenefits && (
                                <div className="sheetDescdiv2" >
                                    <ul className='featuresList'>
                                    {locale==subLocale ? '': (subLocale!='none'? (subLocale=='en'? 
                                            itemdata.features_and_benefits.split('.').map((data, index) => (
                                                data.trim() ? <li key={index}>{data}</li> : null
                                            )): itemdata.features_and_benefits_fr.split('.').map((data, index) => (
                                                data.trim() ? <li key={index}>{data}</li> : null
                                            ))): '')}
                                    </ul>
                                </div>
                                 )}
                                 </div>
                            </div>
                            {/* <div  className="featuresDiv" ref={measuredRef} style={{ fontSize: `${fontSize1}px`,marginTop:'10%'}}  > */}
                            <div  className="featuresDiv" ref={measuredRef} style={{ fontSize: `16.5px`,marginTop:'10%'}}  >
                             {(handlingInstructions || subHandlingInstructions) && (
                             <p className="sheetTitle" style={{ color: brandcolor }}>{makeSalesSheetTitles("Handling Instructions", locale, subLocale)}</p>
                             )}
        
                            {handlingInstructions && (
                                <p className="sheetDescdiv2"   >
                                {locale === 'en' ? itemdata.handling_Instructions : itemdata.handling_Instructions_fr}
                                </p>
                            )}
                            {subHandlingInstructions && (
                                <p className="sheetDescdiv2" >
                                {locale === subLocale ? '' : (subLocale !== 'none' ? (subLocale === 'en' ? itemdata.handling_Instructions : itemdata.handling_Instructions_fr) : '')}
                                </p>
                            )}                        
                            </div>              
                        </div>
                    </div>

{/* 
{(itemdata.ingredients_en.length > 1400 || itemdata.ingredients_fr.length > 1500) ? (
    // Render this block if ingredients length is greater than 1500
    <div className="inside row3">
        <div className="insiderIMGflex2">
            <div>
            </div>
        </div>
        <div className="insiderIMGflex1">
            <div className="imagedataDiv1">
                <img src={nurtiImg} alt="Image not found" className="nutriImg" onError={handleImageError} />
            </div>
        </div>
        <div className="page-break">
          
        <div className="inside row3" >
        <div className="insiderIMGflex2 red-background">
            <div>
                <p className="sheetTitle" style={{ color: brandcolor }}>
                    {makeSalesSheetTitles("Ingredients", locale, subLocale)}
                </p>
                <p className="sheetDescIng">
                    {locale === 'en' ? itemdata.ingredients_en : itemdata.ingredients_fr}
                </p>
                <p className="sheetDescIng">
                    {locale === subLocale ? '' : (subLocale !== 'none' ? (subLocale === 'en' ? itemdata.ingredients_en : itemdata.ingredients_fr) : '')}
                </p>
            </div>
        </div>
        <div className="insiderIMGflex1">
            <div className="imagedataDiv1">
          
            </div>
        </div>
    </div>
   
    </div>
    </div>
) : (
    // Render this block if ingredients length is less than or equal to 1500
    <div className="inside row3">
        <div className="insiderIMGflex2">
            <div>
                <p className="sheetTitle" style={{ color: brandcolor }}>
                    {makeSalesSheetTitles("Ingredients", locale, subLocale)}
                </p>
                <p className="sheetDescIng">
                    {locale === 'en' ? itemdata.ingredients_en : itemdata.ingredients_fr}
                </p>
                <p className="sheetDescIng">
                    {locale === subLocale ? '' : (subLocale !== 'none' ? (subLocale === 'en' ? itemdata.ingredients_en : itemdata.ingredients_fr) : '')}
                </p>
            </div>
        </div>
        <div className="insiderIMGflex1">
            <div className="imagedataDiv1">
                <img src={nurtiImg} alt="Image not found" className="nutriImg" onError={handleImageError} />
            </div>
        </div>
       
    </div>
)} 
*/}
 <div className="inside row3">
 <div className="insiderIMGflex2">
            <div>
                <p className="sheetTitle" style={{ color: brandcolor }}>
                    {makeSalesSheetTitles("Ingredients", locale, subLocale)}
                </p>
                <p className="sheetDescIng">
                    {locale === 'en' ? itemdata.ingredients_en : itemdata.ingredients_fr}
                </p>
                <p className="sheetDescIng">
                    {locale === subLocale ? '' : (subLocale !== 'none' ? (subLocale === 'en' ? itemdata.ingredients_en : itemdata.ingredients_fr) : '')}
                </p>
            </div>
        </div>
        <div className="insiderIMGflex1">
            <div className="imagedataDiv1">
                <img src={nurtiImg} alt="Image not found" className="nutriImg" onError={handleImageError} />
            </div>
        </div>
       
    </div>
<div className='footer'> <p className="sheetfooter">Last Modified: {itemdata.modified_Date ?? ''}</p>
    <p className="sheetfooter" style={{ color: brandcolor }}>{itemdata.brand_Website ?? ''}</p>
    <p className="sheetfooter">{''}</p></div>
                  
                </div>
            </div>) : <span className='loader'></span>}




        </div>

        )


    );
};
export default SalesReport;